<template lang='pug'>
	.videos
		#videos
		h2 Stutt myndbönd
		.content(v-if='mobile')
			.item(v-for='(item, index) in displayedVideos')
				video(controls @playing='play' :class='videoClass(index)' :poster='item.poster')
					source(:src='item.url' type='video/mp4')
				h3 {{ item.title }}
				p.small {{ item.paragraph }}
		.content(v-else)
			.dash-line
			.item(v-for='(item, index) in displayedVideos')
				.even(v-if='index % 2 === 0')
					video(controls @playing='play' :class='videoClass(index)' :poster='item.poster')
						source(:src='item.url' type='video/mp4')
					.circle-wrap
						.circle
					.text
						.wrapper
							h3 {{ item.title }}
							p.small {{ item.paragraph }}
				.odd(v-else)
					.text
						.wrapper
							h3 {{ item.title }}
							p.small {{ item.paragraph }}
					.circle-wrap
						.circle
					video(controls @playing='play' :class='videoClass(index)' :poster='item.poster')
						source(:src='item.url' type='video/mp4')
		.more(@click='loadMore' :class='{ hidden: hideButton || totalDisplayed === videos.length }') Sjá fleiri myndbönd
</template>

<script>
export default {
	data: function () {
		return {
			mobile: false,
			hideButton: false,
			totalDisplayed: 3,
			videos: [
				{
					url: '/videos/video3.mp4',
					poster: '/videos/video3_poster.jpg',
					title: 'Lestur í fríinu',
					paragraph: 'Lestur í aðeins korter á dag getur skipt sköpum fyrir lestrarfærni barna. Jólin eru góður tími til að hafa það náðugt með góðri bók. Sköpum notalegar lestarstundir um jólin og njótum bóka, saman.'
				},
				{
					url: '/videos/video2.mp4',
					poster: '/videos/video2_poster.jpg',
					title: 'Allar bækur eru jólabækur',
					paragraph: 'Að gefa bók í jólagjöf er góð og skemmtileg íslensk hefð. Hvetjum börnin til lesturs, lesum fyrir þau og ræðum saman um lesefnið. Veljum lesefni sem hæfir þroska og áhugamálum barnanna og sköpum notalegar minningar. Gefum bækur og gerum jólin notaleg með uppáhaldsbókunum okkar.'
				},
				{
					url: '/videos/video1.mp4',
					poster: '/videos/video1_poster.jpg',
					title: 'Jólasveinar og bækur í skóinn',
					paragraph: 'Jólasveinarnir eru hrifnir af bókum sem börnin vilja lesa, hvort sem um er að ræða myndasögur, myndabækur eða skáldsögur. Taktu þátt með börnunum í jólasveinalestri og þið gætuð unnið bókaverðlaun!'
				}
			]
		};
	},
	computed: {
		displayedVideos: function () {
			return this.videos.slice(0, this.totalDisplayed);
		}
	},
	created: function () {
		window.addEventListener('resize', this.handleResize);
		this.$nextTick(() => {
			this.handleResize();
		});
	},
	methods: {
		handleResize: function () {
			if (window.innerWidth >= 768) {
				this.mobile = false;
			} else {
				this.mobile = true;
			}
		},
		videoClass: function (index) {
			return 'video-list-' + index;
		},
		play: function (e) {
			let val = e.originalTarget.attributes.class.value;
			for (let i = 0; i < this.videos.length; i++) {
				let currVal = 'video-list-' + i;
				if (val !== currVal) {
					document.getElementsByClassName(currVal)[0].pause();
				}
			}
		},
		loadMore: function () {
			this.totalDisplayed += 3;
			if (this.totalDisplayed >= this.videos.length) {
				this.hideButton = true;
			}
		}
	}
};
</script>

<style lang='less'>
.videos {
	width: 100%;
	padding-top: 50px;
	margin-left: 23px;
	margin-right: 23px;
	#videos {
		position: absolute;
		left: 0px;
		transform: translateY(-180px);
	}
	.more {
		cursor: pointer;
		opacity: 1;
		margin-top: 60px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 22px;
		background-color: #FBB03B;
		height: 44px;
		width: 222px;
		line-height: 44px;
		text-align: center;
		font-size: 18px;
		color: #232323;
		font-weight: bold;
		&.hidden {
			opacity: 0;
		}
	}
	h2 {
		transform: translateY(-50px);
		text-align: center;
	}
	.content {
		text-align: center;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 50px;
		.item {
			video {
				margin-left: auto;
				margin-right: auto;
				width: 100%;
				max-width: 400px;
				max-height: 400px;
			}
		}
	}
	@media screen and (min-width: 768px) {
		margin-left: 64px;
		width: calc(~'100% - 64px');
		h2 {
			padding-top: 100px;
		}
		.more {
				margin-top: 100px;
		}
		.content {
			position: relative;
			.dash-line {
				transition: .3s;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				height: calc(~'100% + 99px');
				border-left: 3px dashed #D0D0D0;
				width: 0px;
			}
			.item {
				.even, .odd {
					display: grid;
					grid-template-columns: 1fr auto 1fr;
					grid-column-gap: 60px;
					margin-bottom: 40px;
					.circle-wrap {
						position: relative;
						.circle {
							position: absolute;
							top: 50%;
							transform: translate(-50%, -50%);
							background-color: #FBB03B;
							width: 34px;
							height: 34px;
							border-radius: 50%;
						}
					}
					.wrapper {
						max-width: 400px;
						position: relative;
						top: 50%;
						transform: translateY(-50%);
						p {
							text-align: left;
							color: #1A3553;
						}
					}
				}
				.even {
					video {
						margin-right: initial;
					}
					.wrapper {
						float: left;
						h3, p {
							text-align: left;
							float: left;
						}
					}
				}
				.odd {
					video {
						margin-left: initial;
					}
					.wrapper {
						float: right;
						h3, p {
							float: right;
							text-align: right;
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 968px) {
		padding-top: 0px;
		margin-left: 64px;
		margin-right: 64px;
		.content {
			.item {
				.text {
					.wrapper {
						h3 {
							font-size: 25px;
						}
					}
				}
			}
		}
	}
}
</style>
