<template lang='pug'>
	.footer
		.wave-container
			img.wave(src='@/assets/bottom_wave.svg')
		.content
			.logo
				img.mms(src='@/assets/mms_logo_litur@2x.png')
			.information
				p.small <span class='bold'> 2018 </span> Menntamálastofnun
				.dash -
				p.small Sími: 514 7500
				.dash -
				p.netfang Netfang: <a href='mailto:postur@mms.is'>postur@mms.is</a>
			.social
				a(href='https://www.facebook.com/Menntamalastofnun/' target='_blank')
					img.fb(src='@/assets/facebook_button_footer.svg')
				.bottom-pad
</template>

<script>
export default {
};
</script>

<style lang='less'>
.footer {
	.content {
		padding-left: 102px;
		padding-right: 102px;
		transform: translateY(-20px);
		background-color: white;
		text-align: center;
		.information {
			.dash {
				visibility: hidden;
				height: 0px;
			}
		}
		p {
			font-size: 14px;
			margin: 0px;
			margin-top: 5px;
		}
		a {
			text-decoration: none;
			transition: .3s;
			color: #656565;
			&:hover {
				color: #1A3553;
			}
		}
		img {
			&.mms {
				margin-bottom: 20px;
				width: 100%;
			}
			&.fb {
				margin-top: 20px;
				width: 32px;
			}
		}
		.bottom-pad {
			position: absolute;
			background-color: white;
			left: 0px;
			bottom: 0px;
			transform: translateY(20px);
			height: 20px;
			width: 100vw;
		}
		@media screen and (min-width: 768px) {
			display: grid;
			grid-template-columns: 2fr 3fr 1fr;
			grid-column-gap: 50px;
			.logo {
				width: 100%;
				text-align: left;
				transform: translateY(-20px);
			}
			.information {
				margin-left: auto;
				margin-right: auto;
				max-width: 516px;
				transform: translateY(-40px);
				width: 100%;
			}
			.social {
				text-align: right;
				img {
					position: relative;
					transform: translateY(-50px);
				}
			}
		}
		@media screen and (min-width: 1076px) {
			grid-template-columns: 1fr 3fr 1fr;
			.information {
				transform: translateY(-23px);
				display: grid;
				grid-template-columns: auto auto auto auto auto;
				.dash {
					transform: translateY(8px);
					text-align: center;
					visibility: visible;
					height: initial;
				}
			}
		}
	}
	@media screen and (min-width: 1366px) {
		overflow-y: hidden;
		.wave {
			position: relative;
			width: 100%;
			height: auto;
			bottom: -100px;
		}
	}
	@media screen and (min-width: 1920px) {
		margin-top: -200px;
		overflow-y: hidden;
		.wave {
			position: relative;
			left: 0px;
			width: 100%;
			height: auto;
			bottom: -200px;
		}
		.content {
			img {
				&.mms {
					margin-bottom: 20px;
					width: 100%;
					transform: translateY(-20px);
				}
				&.fb {
					margin-top: 20px;
					width: 32px;
					transform: translateY(-55px);
				}
			}
		}
	}
}
</style>
