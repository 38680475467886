<template lang='pug'>
	.collaborators
		h2 Lestur er ævilöng iðja
		.items
			img.item.mms(src='@/assets/mms_logo@2x.png')
			img.item.les(src='@/assets/lesferill@2x.png')
			img.item.ugla(src='@/assets/ugla@2x.png')
			img.item.satt(src='@/assets/satt.png')
</template>

<script>
export default {
};
</script>

<style lang='less'>
.collaborators {
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	h2 {
		margin-top: 60px;
		text-align: center;
	}
	.items {
		margin-top: 86px;
		margin-bottom: 86px;
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 50px;
		margin-left: 23px;
		margin-right: 23px;
		img {
			margin-left: auto;
			margin-right: auto;
			&.mms {
				width: 278px;
			}
			&.ugla {
				width: 250px;
			}
			&.les {
				transform: translateX(25px);
				width: 147px;
			}
			&.satt {
				width: 147px;
			}
		}
	}
	@media screen and (min-width: 768px) {
		.items {
			display: grid;
			grid-template-columns: initial;
			grid-row-gap: 80px;
			grid-template-areas:
			'mms ugla'
			'les satt';
			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%) !important;
				&.mms {
					grid-area: mms;
					width: 400px;
				}
				&.les {
					grid-area: les;
					width: 200px;
				}
				&.ugla {
					grid-area: ugla;
					width: 340px;
				}
				&.satt {
					grid-area: satt;
					width: 200px;
				}
			}
		}
	}
	@media screen and (min-width: 1368px) {
		.items {
			margin-top: 120px;
			display: grid;
			grid-template-columns: initial;
			grid-row-gap: 80px;
			grid-template-areas:
			'mms satt les ugla';
			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%) !important;
				&.mms {
					grid-area: mms;
					width: 350px;
				}
				&.les {
					grid-area: les;
					width: 150px;
				}
				&.ugla {
					grid-area: ugla;
					width: 290px;
				}
				&.satt {
					grid-area: satt;
					width: 150px;
				}
			}
		}
	}
}
</style>
