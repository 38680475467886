<template lang='pug'>
	#app
		Nav
		router-view
</template>

<script>
import Nav from '@/components/Nav.vue';
export default {
	name: 'LesumMeira',
	components: { Nav }
};
</script>

<style lang="less">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

.see-more {
	cursor: pointer;
	font-weight: bold;
	color: #F29313;
	text-decoration: none;
	transition: .3s;
	&:hover {
		color: rgb(145, 88, 14);
	}
}

html {
	overflow-x: hidden;
}
body {
	margin: 0px;
	overflow-x: hidden;
	width: 100vw !important;
	background-color: #F8F8F8;
	font-family: 'TipoType';
}

* {
	max-width: 100vw;
}

.bold {
	font-weight: bold;
}

h1 {
	font-size: 45px;
	font-weight: normal;
	color: #232323;
	@media screen and (min-width: 768px) {
		font-size: 60px;
	}
}

h2 {
	font-size: 30px;
	font-weight: bold;
	color: #1A3553;
	@media screen and (min-width: 768px) {
		font-size: 40px;
	}
}

h3 {
	font-size: 20px;
	color: #232323;
	font-weight: bold;
	&.orange {
		color: #F29313;
	}
}

h4 {
	color: #232323;
	font-weight: bold;
}

p {
	font-size: 18px;
	font-weight: normal;
	color: #656565;
	line-height: 25px;
	max-width: 788px;
	&.small {
		font-size: 16px;
	}
}

// Debug
.debug {
	border: 1px solid red;
}

// Fonts
@font-face {
	font-family: "TipoType";
	src: url("/fonts/TipoType-Amelia-Rounded-Black.otf") format("opentype");
	font-weight: 900;
}
@font-face {
  font-family: "TipoType";
  src: url("/fonts/TipoType-Amelia-Rounded-Black-Italic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "TipoType";
  src: url("/fonts/TipoType-Amelia-Rounded-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "TipoType";
  src: url("/fonts/TipoType-Amelia-Rounded-Bold-Italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
	font-family: "TipoType";
	src: url("/fonts/TipoType-Amelia-Rounded-Light.otf") format("opentype");
	font-weight: 300;
}
@font-face {
	font-family: "TipoType";
	src: url("/fonts/TipoType-Amelia-Rounded-Light-Italic.otf") format("opentype");
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "TipoType";
	src: url("/fonts/TipoType-Amelia-Rounded-Regular.otf") format("opentype");
	font-weight: 400;
}
@font-face {
	font-family: "TipoType";
	src: url("/fonts/TipoType-Amelia-Rounded-Regular-Italic.otf") format("opentype");
	font-weight: 400;
	font-style: italic;
}
</style>
