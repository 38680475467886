<template lang='pug'>
	.interviews
		#interviews
		h2.mobile Viðtöl
		carousel(ref='carousel1' :perPage='1' @pageChange='(val) => trackChange(val)')
			slide.items(v-for='(item, index) in videos' :key='index')
				.video-slide
					.video
						video(:class='getClass(index)' controls)
							source(:src='item.url' type='video/mp4')
					.content
						h2.desktop Viðtöl
						h3 {{ item.title }}
						p.small {{ item.paragraph }}
						.pagination.desktop
							.left(:class='{ disabled: !prevTitle.length }')
								img(src='@/assets/arrow_orange.svg' @click='prevPage')
								p(@click='prevPage') {{ prevTitle }}
							.right(:class='{ disabled: !nextTitle.length }')
								img(src='@/assets/arrow_orange.svg' @click='nextPage')
								p(@click='nextPage') {{ nextTitle }}
		.pagination.mobile
			.left(:class='{ disabled: !prevTitle.length }')
				img(src='@/assets/arrow_orange.svg' @click='prevPage')
				p(@click='prevPage') {{ prevTitle }}
			.right(:class='{ disabled: !nextTitle.length }')
				img(src='@/assets/arrow_orange.svg' @click='nextPage')
				p(@click='nextPage') {{ nextTitle }}
</template>

<script>
export default {
	data: function () {
		return {
			currentPage: 0,
			videos: [
				{
					url: '/interviews/salka_sol.mp4',
					title: 'Salka Sól Eyfeld',
					paragraph: 'Salka Sól Eyfeld er söngkona, leikkona og dagskrárgerðarmaður.',
					nextTitle: 'Óli Gunnar Gunnarsson',
					prevTitle: ''
				},
				/*
					Salka Sól Eyfeld er söngkona, leikkona og dagskrárgerðarmaður. Í grunnskóla fannst Sölku erfitt að lesa þessar gömlu Íslendingasögur en hún er fegin að hafa klórað sig í gegnum þær því hún vill þekkja þessar sögur. Salka heldur að það hjálpi börnum mikið að tengja við textann sem þau lesa og að þau hafi áhuga á innihaldinu. Þá ósjálfrátt fari þau að skilja meira og þjálfi sig og skilji annað efni líka.
					„Ef þú leggur þig fram við að skilja og lesa mikið þá þjálfar þú þig og verður góð eða góður í því. Til að vera góður rappari þarftu að hafa gífurlega góð tök á íslensku“, segir Salka.

				*/
				{
					url: '/interviews/oli_gunnar.mp4',
					title: 'Óli Gunnar Gunnarsson',
					paragraph: 'Óli Gunnar Gunnarsson er rithöfundur og leikari.',
					nextTitle: 'Þuríður Blær Jóhannesdóttir',
					prevTitle: 'Salka Sól Eyfeld'
				},
				/*
					Óli Gunnar Gunnarsson er rithöfundur og leikari. Óli var ekkert sérstaklega duglegur að ná málsháttunum undir pressu en hann skemmti sér samt mjög vel við þessa keppni. Óla finnst lesskilningur mjög mikilvægur og fannst sér ganga mjög vel í því í skóla. Að hans mati kemur lestur sér vel fyrir alla, hvort sem maður er ungur eða gamall. Persónulega heldur Óli að fólk lesi of lítið þessa dagana.
				*/
				{
					url: '/interviews/thuridur_blaer.mp4',
					title: 'Þuríður Blær Jóhannesdóttir',
					paragraph: 'Þuríður Blær Jóhannesdóttir er leikkona og rappari.',
					nextTitle: 'Arnór Björnsson',
					prevTitle: 'Óli Gunnar Gunnarsson'
				},
				/*
					Þuríður Blær Jóhannesdóttir er leikkona og rappari og hún gerði sér lítið fyrir og vann málsháttahraðakeppnina okkar. Þuríður man eftir að hafa þurft að lesa ákveðið efni sem hún tengdi ekki við en þegar hún er að lesa efni sem höfðar til hennar þá fer hún inn í það efni með öðru hugarfari. Hún segir að lestur og lesskilningur sé mjög mikilvægur þegar maður er rappari og sama á við um að hafa vald á tungumálinu.
					„Það er ekki hægt að leika sér að tungumálinu nema þú þekkir það vel“, segir Þuríður Blær.
				*/
				{
					url: '/interviews/arnor_bjornsson.mp4',
					title: 'Arnór Björnsson',
					paragraph: 'Arnór Björnsson er rithöfundur og leikari.',
					nextTitle: 'Margrét Lára Viðarsdóttir',
					prevTitle: 'Þuríður Blær Jóhannesdóttir'
				},
				/*
					Arnór Björnsson er rithöfundur og leikari. „Baby, ég vissi alveg af þér en ég held ég þurfi að fara að koma mér“, er málsháttur að mati Arnórs. Hann var aðeins betri en Óli í málshátta-hraðakeppninni okkar. Það merkilegasta sem lestur hefur gert fyrir Arnór er að veita honum innblástur í annað „stöff“ sem hann skrifar. Ef hann og Óli kynnu ekki að lesa þá gætu þeir ekki skrifað! Arnór á sér svo sannarlega uppáhaldsbók en það er Hobbitinn, sem er „negla af bók“ að hans mati.
				*/
				{
					url: '/interviews/margret_lara.mp4',
					title: 'Margrét Lára Viðarsdóttir',
					paragraph: 'Margrét Lára Viðarsdóttir er landsliðskona í fótbolta.',
					nextTitle: 'Björgvin Páll Gústavsson',
					prevTitle: 'Arnór Björnsson'
				},
				/*
					Margrét Lára Viðarsdóttir er landsliðskona í fótbolta. Margrét Lára telur lestur og lesskilning mjög mikilvægan í sínu starfi, bæði í fótboltanum og líka í sálfræðinni. Það skiptir mjög miklu máli fyrir íþróttafólk sem er t.d. að koma fram og er áberandi að það tali fallega og góða íslensku og hún er þakklát fyrir að hafa fengið gott uppeldi í lestri.
					Uppáhaldsbókin hennar er Skaraðu fram úr. Að mati Margrétar er lestur mikilvægur því fólk fær allar sínar upplýsingar og allt sem það þarf að vita í gegnum samskipti við fólk og lestur.
				*/
				{
					url: '/interviews/bjorgvin_pall.mp4',
					title: 'Björgvin Páll Gústavsson',
					paragraph: 'Björgvin Páll Gústavsson er markmaður og atvinnumaður í handbolta.',
					nextTitle: '',
					prevTitle: 'Margrét Lára Viðarsdóttir'
				}
				/*
					Björgvin Páll Gústavsson er markmaður og atvinnumaður í handbolta. Hann bjó í Þýskalandi í níu ár og var þess vegna ekki duglegur í málsháttunum (að eigin mati) þegar hann var beðinn óvænt að telja upp eins marga málshætti og hann gat á 15 sekúndum. Eftir erfiða leiki finnst honum fátt betra en að leggjast upp í rúm með góða bók. Björgvin átti ekkert sérstaklega auðvelt með að lesa þegar hann var yngri en keppnismaðurinn í honum notaði lestrarátökin í skóla til að koma sér í gírinn. Hann telur lestur mjög mikilvægan fyrir ungt fólk í dag.
					Björgvin Páll ber handboltann og lestur saman og segir: „Þetta er bara ein æfing í að lesa eins og hver önnur. Aukaæfingin skapar meistarann og eigum við ekki bara að segja að aukabókin skapi líka meistarann“.
				*/
			]
		};
	},
	computed: {
		nextTitle: function () {
			return this.videos[this.currentPage].nextTitle;
		},
		prevTitle: function () {
			return this.videos[this.currentPage].prevTitle;
		}
	},
	methods: {
		trackChange: function (val) {
			this.currentPage = val;
			for (let i = 0; i < this.videos.length; i++) {
				document.getElementsByClassName(`video-${i}`)[0].pause();
			}
		},
		getClass: function (index) {
			return `video-${index}`;
		},
		prevPage: function () {
			if (this.currentPage > 0) {
				this.currentPage--;
				this.$refs.carousel1.goToPage(this.currentPage);
			}
		},
		nextPage: function () {
			if (this.currentPage < this.$refs.carousel1.slideCount - 1) {
				this.currentPage++;
				this.$refs.carousel1.goToPage(this.currentPage);
			}
		}
	}
};
</script>

<style lang='less'>
.interviews {
	#interviews {
		transform: translateY(-150px);
	}
	position: relative;
	margin-top: 80px;
	h2 {
		text-align: center;
	}
	.VueCarousel-pagination {
		display: none;
	}
	.pagination {
		padding-bottom: 55px;
		background-color: white;
		padding-top: 40px;
		display: grid;
		grid-template-columns: 50vw 50vw;
		img {
			width: 9px;
		}
		.left {
			p {
				cursor: pointer;
				font-size: 13px;
				text-align: left;
				transform: translate(10px, -13px);
				color: #FBB03B;
				transition: .3s;
				&:hover {
					color: rgb(145, 88, 14) !important;
				}
			}
			img {
				cursor: pointer;
				float: left;
				transform: rotate(180deg);
				margin-left: 23px;
			}
			&.disabled {
				p {
					color: #BEBEBE;
				}
				img {
					filter: grayscale(100%);
				}
			}
		}
		.right {
			p {
				cursor: pointer;
				font-size: 13px;
				text-align: right;
				transform: translate(-10px, -13px);
				color: #FBB03B;
				transition: .3s;
				&:hover {
					color: rgb(145, 88, 14) !important;
				}
			}
			img {
				cursor: pointer;
				float: right;
				margin-right: 23px;
			}
			&.disabled {
				p {
					color: #BEBEBE;
				}
				img {
					filter: grayscale(100%);
				}
			}
		}
	}
	.items {
		background-color: white;
		.desktop {
			display: none;
		}
		h3 {
			margin-top: 44px;
			font-size: 25px;
			font-weight: bold;
			color: #FBB03B;
			text-align: center;
		}
		p {
			padding-left: 23px;
			padding-right: 23px;
			max-width: 568px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			color: #1A3553;
		}
	}
	@media screen and (min-width: 968px) {
		.items {
			.video-slide {
				display: grid;
				grid-template-columns: 1fr 1fr;
				.video {
					video {
						width: 100%;
					}
				}
				.content {
					position: relative;
					padding-left: 23px;
					padding-right: 23px;
				}
			}
		}
		.pagination {
			.left {
				p {
					font-size: 18px;
					transform: translate(50px, -22px);
				}
			}
			.right {
				p {
					font-size: 18px;
					transform: translate(-50px, -22px);
				}
			}
		}
	}
	@media screen and (min-width: 1366px) {
		.mobile {
			visibility: hidden;
		}
		.items {
			.video-slide {
				.video {
					padding: 0px;
				}
				.content {
					padding-top: 50px;
					padding-left: 130px;
					.pagination {
						position: absolute;
						width: calc(~'100% - 50px');
						left: 26px;
						bottom: 0px;
						background-color: white;
						padding-top: 40px;
						display: grid;
						grid-template-columns: 50% 50%;
						padding-bottom: 0px;
						.left {
							p {
								cursor: pointer;
								font-size: 18px;
								text-align: left;
								transform: translate(50px, -22px);
								color: #FBB03B;
							}
							img {
								cursor: pointer;
								float: left;
								transform: rotate(180deg);
								margin-left: 23px;
							}
							&.disabled {
								p {
									color: #BEBEBE;
								}
								img {
									filter: grayscale(100%);
								}
							}
						}
						.right {
							p {
								cursor: pointer;
								font-size: 18px;
								text-align: right;
								transform: translate(-50px, -22px);
								color: #FBB03B;
							}
							img {
								cursor: pointer;
								float: right;
								margin-right: 23px;
							}
							&.disabled {
								p {
									color: #BEBEBE;
								}
								img {
									filter: grayscale(100%);
								}
							}
						}
					}
					h2.desktop {
						display: block;
						text-align: left;
					}
					h3 {
						margin-top: 44px;
						font-size: 25px;
						font-weight: bold;
						color: #FBB03B;
						text-align: left;
					}
					p {
						padding-left: 0px;
						margin-left: 0px;
						margin-right: 23px;
						max-width: 568px;
						text-align: left;
						color: #1A3553;
					}
				}
			}
		}
	}
}
</style>
