<template lang='pug'>
	.modal
		sweet-modal(ref='modal' @close='hide' v-if='which === 0')
			.content#modalcontent
				h3.orange Lesskilningur
				.top
					.text
						p Í dag eru gerðar kröfur um raunprófaðar kennsluaðferðir sem eiga að tryggja börnum faglega kennslu. Þjálfun heima fyrir er eftir sem áður mikilvæg og getur ráðið úrslitum um að barnið nái að fylgja jafnöldrum. Reglulegur heimalestur er mikilvægari en margir gera sér grein fyrir. Verum því dugleg að fylgja þessu eftir, árið um kring. Miklu skiptir að viðhalda þeirri færni sem þegar hefur verið náð með því að sjá til þess að barnið lesi líka í fríum. Rannsóknir sýna að það getur tekið tíma að ná upp þeirri færni sem barnið var með fyrir frí ef ekki er haldið vel á spöðunum. Ágætt er að miða við minnst korter á dag. Miklu skiptir að kröfur séu við hæfi, hvorki of miklar né litlar.
					.image
						img(src='@/assets/teikning1@2x.png')
				.list
					.dot
					p Sýnið lestri barnsins áhuga og hrósið þegar við á.
					.dot
					p Hlustið á barnið lesa daglega.
					.dot
					p Gerið lestrarstundina að jákvæðri upplifun, leiðréttið af nærgætni.
					.dot
					p Gætið þess að þyngd texta til lesþjálfunar hæfi lestrargetu barnsins.
					.dot
					p Takið hlé ef barnið þreytist og gætið þess að það sé ekki svangt.
					.dot
					p Gott er að skiptast á að lesa ef textinn reynist erfiður.
					.dot
					p Leggið áherslu á að barnið læri bókstafina og hljóð þeirra.
					.dot
					p Hjálpið barninu að tengja hljóð saman svo úr verði orð. Ritun er öflug leið til að þjálfa næmi fyrir tengslum bókstafa og hljóða og örva hljóðkerfisvitund.
					.dot
					p Notist við hljóðbækur. Til dæmis í bílnum, á kvöldin þegar líður að háttatíma og við fleiri tækifæri.
					.dot
					p Fáið börn til að lesa í fríum og takið bækur með í ferðalög.
					.dot
					p Leitið frekari leiðbeininga hjá kennara barnsins um hvernig best er að haga heimanámi í lestri og ritun, um þyngd lesefnis og kröfur.
				p Lestur er ein flóknasta færni sem barn þarf að ná tökum á í lífinu og því er mikilvægt að halda vel utan um lestrarþjálfun barnsins. Lestur er ævilöng iðja.
				.extra-text
					p.citation Texti fenginn frá <a href='http://www.heimiliogskoli.is' class='see-more' target='_blank'> Heimili og skóla </a>
					a.see-more(href='http://lesvefurinn.hi.is/node/137' target='_blank') Hægt er að lesa meira á Lesvefnum

		sweet-modal(ref='modal' @close='hide' v-if='which === 1')
			.content#modalcontent
				h3.orange Orðaforði
				.top
					.text
						p Mikilvægt er að hlusta reglulega á börn lesa og gera það af áhuga og athygli. Börn læra málið af samskiptum við annað fólk, oftast án sérstakrar kennslu. Lestur þarf hins vegar að kenna og þjálfa. Lestur ætti fyrst og fremst að vera ánægjuleg reynsla og miklu skiptir að börnum finnist þau ráða við viðfangsefnið. Mikilvægt er að börn og unglingar haldi áfram að lesa eftir að lágmarksfærni er náð og er það í raun forsenda þess að þau ráði við stöðugt erfiðara lesefni og flóknari orðaforða. Gott er að ræða saman um textann til að ganga úr skugga um að barnið þjálfi skilning á lesefninu. Til dæmis er gagnlegt að fara í gegnum eftirtalin atriði:
					.image
						img(src='@/assets/teikning3@2x.png')
				.list
					.dot
					p Spyrja um söguþráðinn
					.dot
					p Spyrja út í persónur
					.dot
					p Hver er boðskapur sögunnar?
					.dot
					p Gæti sagan gerst í alvörunni?
					.dot
					p Hvað myndir þú gera?
					.dot
					p Hvernig leið þér eftir lesturinn?
					.dot
					p Varðstu einhvers vísari?
					.dot
					p Fannst þér erfitt að skilja einhver orð?
					.dot
					p Hvað fannst þér mest spennandi eða skemmtilegast?
					.dot
					p Hvað gæti gerst næst?
				p Lesefni skólastiganna þyngist með hverju stigi og því er mikilvægt að fylgjast vel með framförum alla grunnskólagönguna, bæði í lesfimi og lesskilningi. Foreldrar setja oft reglur eða komast að samkomulagi við börn um hversu langan tíma þau fá til að vera í tölvu, horfa á sjónvarp o.s.frv. Sama ætti að gilda um lestur. Börnum líkar að hafa hlutina í föstum skorðum og gott er að koma á rútínu með lestur eins og aðra hluti. Auðvelt er að venja börn við að lesa t.d. hálftíma á dag og þau ættu að lágmarki að lesa upphátt í u.þ.b. 15 mínútur á dag. Við eigum öll korter til að hlusta á börnin okkar lesa.
				.extra-text
					p.citation Texti fenginn frá <a href='http://www.heimiliogskoli.is' class='see-more' target='_blank'> Heimili og skóla </a>
					a.see-more(href='http://lesvefurinn.hi.is/node/231' target='_blank') Hægt er að lesa meira á Lesvefnum

		sweet-modal(ref='modal' @close='hide' v-if='which === 2')
			.content#modalcontent
				h3.orange Lesfimi
				.top
					.text
						p Lesfimi er færni sem byggir á nákvæmni, sjálfvirkni og hrynrænum þáttum tungumálsins en allir þessir þættir stuðla að auknum lesskilningi. Hún birtist í sjálfvirkum fyrirhafnarlausum lestri í viðeigandi hendingum og með réttu hljómfalli. Lesfimi er mikilvæg við upplestur og hljóðlestur. Slök lesfimi getur hindrað lesskilning því einbeitingin fer í að lesa orðin en ekki að velta innihaldi textans fyrir sér.
					.image
						img(src='@/assets/teikning2@2x.png')
				h3 Þjálfun lesturs heima
				p Mikilvægt er að hafa í huga að í skólanum fer lestrarkennsla fram en þjálfunin fer fram heima. Foreldrar er mikilvægar fyrirmyndir barna sinna og þau börn sem alast upp við jákvætt lestrarhvetjandi umhverfi, sjá foreldra sína lesa og upplifa að bækur og ritmál sé hluti af daglegu umhverfi, læra að njóta lestrar.
				.extra-text
					a.see-more(href='http://lesvefurinn.hi.is/node/209' target='_blank') Hægt er að lesa meira á Lesvefnum

		sweet-modal(ref='modal' @close='hide' v-if='which === 3')
			.content#modalcontent
				h3.orange Korter á dag
				.top
					.text
						p Líklegra er að barn lesi ef það hefur eitthvað skemmtilegt að lesa. Sjálfsagt er að eiga bækur heima fyrir þegar andinn kemur yfir okkur en einnig getur verið áhrifaríkt að gerast áskrifandi að áhugaverðu efni. Einfalt er að útvega sér bækur í gegnum netið ef til er snjalltæki á heimilinu. Auk þessa er gaman að gera sér ferð á bókasafn, lesa þar og fá lánaðar bækur sem hæfa aldri barns og þroska.
					.image
						img(src='@/assets/teikning4@2x.png')
				p Ef barnið ræður ekki sjálft við texta er tilvalið að lesa fyrir það og fá hljóðbækur að láni. Þannig kynnist barn sem á erfitt með lestur þeim orðaforða og þeirri þekkingu sem læsir jafnaldrar fást við. Fylgist með að barnið fái hljóðbækur í lesgreinum sé þess þörf en þess fyrir utan geta hljóðbækur gagnast öllum börnum og verið ágætis tilbreyting. Gagnlegt getur verið að lesa saman bók sem gerð hefur verið kvikmynd eftir og horfa síðan á myndina að lestri loknum, eða öfugt. Horfa á kvikmynd og lesa síðan bókina. Þá má ræða mismunandi upplifanir af lestri bókarinnar og bera saman við áhorf myndarinnar.
				p Stundum skortir viljann eða lestrarhvötina. Til að örva áhuga á lestri er nauðsynlegt að gefa börnum kost á að hafa hönd í bagga með hvað þau lesa. Um leið og lestrarefnið snýst um eitthvað sem vekur athygli þeirra og áhuga eykur það alla jafna hvatann til að lesa. Fyrst kemur viljinn og getan fylgir í kjölfarið. Gott aðgengi að bókum bætir læsi og því er mikilvægt fyrir öll börn að geta auðveldlega nálgast bækur. Þá er rétt að nefna að börn geta fengið bókasafnsskírteini endurgjaldslaust.
				.extra-text
					p.citation Texti fenginn frá <a href='http://www.heimiliogskoli.is' class='see-more' target='_blank'> Heimili og skóla </a>
					a.see-more(href='http://lesvefurinn.hi.is/node/231' target='_blank') Hægt er að lesa meira á Lesvefnum

		sweet-modal(ref='modal' @close='hide' v-if='which === 4')
			.content#modalcontent
				//-
				h3.orange Ráðgjöf, stuðningur og starfsþróun
				p Menntamálastofnun veitir sveitarfélögum, leik- og grunnskólum og foreldrum ráðgjöf og stuðning.
				h4 Ráðgjöfin fer fram með ýmsum hætti:
				.list
					.dot
					p Haldnir eru fjarfundir
					.dot
					p Skólafólk sækir fyrirlestra í Menntamálastofnun
					.dot
					p Farið er með ráðgjöf í sveitarfélög og skóla
					.dot
					p Fræðsla og kynningar á rafrænu formi.
				h4 Aðaláherslur ráðgjafarinnar eru:
				.list
					.dot
					p Grunnþættir læsis
					.dot
					p Gerð læsisstefnu
					.dot
					p Nýting tölulegra gagna
					.dot
					p Áætlanagerð
					.dot
					p Íslenska sem annað tungumál
					.dot
					p Samstarf milli skólastiga
					.dot
					p Samstarf við foreldra
				p Haustið 2018 hefur Menntamálastofnun haft aðkomu að flestum sveitafélögum með ráðgjöf og fræðslu varðandi eflingu læsis.
				p Sveitarfélög og skólar geta sent inn beiðni um ráðgjöf í gegnum vef Menntamálastofnunar.
				//-
				h3.orange.pad Lesferill
				h4 Lesferill er safn staðlaðra mælitækja sem metur framvindu nemenda í læsi til þess að:
				.dot
				p Koma sem best til móts við hvern og einn
				.dot
				p Fylgjast grannt með framvindu í læsi.
				h4 Matstæki Lesferils meta:
				.list
					.dot
					p Lesfimi
					.dot
					p Lesskilning
					.dot
					p Orðaforða
					.dot
					p Hljóðkerfis- og hljóðavitund
					.dot
					p Málþroska
				h4 Matstæki enn í vinnslu:
				.list
					.dot
					p Ritun
					.dot
					p Réttritun
				h4 Með Lesferli er hægt að aðlaga skólastarf betur að þörfum hvers og eins nemanda.
				h4 Matstæki Lesferils eru auðveld í notkun.
				h4 Samanburðarhæfar mælingar.
				//-
				h3.orange.pad Foreldrar í lykilhlutverki
				p
				.list
					.dot
					p Rannsóknir hafa sýnt að foreldrar gegna lykilhlutverki í námi barna sinna og ástæða er til að hvetja þá til virkrar þátttöku.
					.dot
					p Foreldrar eru sterkar fyrirmyndir í lífi barna sinna. Börn sem alast upp við jákvætt lestrarhvetjandi umhverfi, sjá foreldra sína lesa og upplifa að bækur og ritmál sé hluti af daglegu umhverfi, læra að njóta lestrar.
					.dot
					p Mikilvægur hluti verkefnisins er að upplýsa foreldra um tilgang Þjóðarsáttmála um læsi og gera þeim grein fyrir að þeir eru í raun hluti af honum. Lestrarkennsla fer fram í skólanum en þjálfunin fer að miklu leyti fram heima.
					.dot
					p Menntamálastofnun er í góðu samstarfi við samtök Heimilis og skóla. Samtökin unnu Læsissáttmála í samstarfi við hóp fagfólks.
					.dot
					p Að barn nái tökum á lestri snýst ekki einungis um gott gengi í skóla. Það hefur áhrif á framtíðarmöguleika og frelsið til að geta valið úr þeim tækifærum sem lífið hefur upp á að bjóða.
				//-
				h3.orange.pad Samstarf – Ráðstefnur
				p Vinna að bættum árangri í lestri barna er verkefni samfélagsins alls.
				p Menntamálastofnun hefur stofnað til samstarfs við hina ýmsu aðila og átt frumkvæði að samstarfsverkefnum er stuðla að jöfnuði þannig að öll börn hafi jafnan aðgang að læsishvetjandi efni.
				//-
				h3.orange.pad Samstarfsaðilar eru m.a.
				p
				.list
					.dot
					p Leikskólar
					.dot
					p Grunnskólar
					.dot
					p Heimili og skóli
					.dot
					p KrakkaRÚV
					.dot
					p SÍUNG – Rithöfundar barna- og unglingabóka
					.dot
					p Bókasöfnin í landinu
					.dot
					p Kennarasamband Íslands
					.dot
					p Háskólasamfélagið
					.dot
					p Samband íslenskra sveitarfélaga.
				//-
				h3.orange.pad Árleg læsisráðstefna
				p Menntamálastofnun heldur árlega ráðstefnu um læsi í samstarfi við háskólasamfélagið. Megináhersla er lögð á fræðslu til starfandi kennara leik- og grunnskóla og er byggt hvorutveggja á fræðilegum- og hagnýtum grunni.
				//-
				h3.orange.pad Lærdómssamfélagið
				p Þjóðarsáttmáli um læsi er umfangsmikið verkefni, en með samstöðu og samtakamætti lærdómssamfélagsins, þ.e. skólans, foreldranna, nemandans og samfélagsins alls, ættu markmiðin að nást.
				h4 Það sem einkennir öflugt lærdómssamfélag er:
				.list
					.dot.num 1.
					p Að samstaða sé um aðgerðir til eflingar læsis.
					.dot.num 2.
					p Að það sé forgangsverkefni menntamálayfirvalda, sveitarstjórna, skóla, kennara, foreldra og samfélagsins alls að stuðla að læsi nemenda.
					.dot.num 3.
					p Að lærdómssamfélagið sé grundvallað á þeim gildum að gæði skóla eða fræðsluumdæmis verði aldrei meiri en hæfni kennara og stjórnenda gefur tilefni til.
					.dot.num 4.
					p Að byggja upp faglega hæfni einstaklinga og hópa til að takast á við stöðugar umbætur er því hornsteinn lærdómssamfélagsins (Dufour og Fullan, 2013).
				p Mikilvægt er að horfa á umbætur í menntun og lestrarkunnáttu til lengri tíma og byggja upp nauðsynlegar grunnstoðir til þess.

</template>

<script>
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
	components: { SweetModal, SweetModalTab },
	props: {
		activated: {
			type: Boolean,
			default: false
		},
		which: {
			type: Number,
			default: 0
		}
	},
	watch: {
		activated: function () {
			if (this.activated) {
				this.$refs.modal.open();
				$('#modalcontent').trigger('click');
				$('#home').on('wheel.modal mousewheel.modal', function (e) {
					if (e.target.className.includes('sweet-modal-overlay')) {
						return false;
					}
				});
			}
		}
	},
	methods: {
		hide: function () {
			$('#home').off('wheel.modal mousewheel.modal');
			this.$emit('hide');
		}
	}
};
</script>
<style lang="less">
.sweet-content {
	text-align: left !important;
	height: 60vh;
	overflow-y: scroll;
}
.sweet-action-close {
	transform: translateX(-10px);
}
</style>

<style lang='less' scoped>
.content {
	background-color: white;
	z-index: 500;
	transition: .3s;
	h3 {
		margin: 0px;
		&.pad {
			margin-top: 30px;
		}
	}
	.extra-text {
		p, a {
			font-size: 16px;
		}
		padding-bottom: 50px;
	}
	.top {
		display: grid;
		grid-template-columns: auto auto;
		.image {
			display: inline-block;
			img {
				width: 200px;
				margin-bottom: 10px;
			}
		}
		p {
			padding-top: 10px;
			width: 100%;
			max-width: 600px;
		}
	}
	.list {
		display: grid;
		grid-template-columns: 9px auto;
		grid-gap: 10px;

		.dot {
			background-color: #F29313;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			transform: translateY(6px);
			&.num {
				background-color: rgba(0,0,0,0);
			}
		}
		p {
			font-size: 16px;
			margin: 0px;
		}
	}
}
</style>
