<template lang='pug'>
	.about
		#about
		.content
			.content-1
				h2 Um verkefnið
				p Árið 2015 undirrituðu fulltrúar allra sveitarfélaga á landinu, formaður Heimilis og skóla og menntamálaráðherra, Þjóðarsáttmála um læsi. Menntamálastofnun stýrir verkefninu.
				p.small.see-more(@click='seeMore(4)') Sjá nánar.
			.content-2
				.item
					.image
						img(src='@/assets/teikning1@2x.png')
					h3 Lesskilningur
					p.small Gott er að spyrja börnin út í atburðarás sögunnar. Ræða helstu persónur, sögusvið, tíma og hvernig þeim finnst sagan.
					p.small.see-more(@click='seeMore(0)') Sjá nánar.
				.item
					.image
						img(src='@/assets/teikning3@2x.png')
					h3 Orðaforði
					p.small Gott er að segja sögur eða lesa fyrir börn frá unga aldri. Fjölbreytt lesefni eykur orðaforða og vekur áhuga barna.
					p.small.see-more(@click='seeMore(1)') Sjá nánar.
				.item
					.image
						img(src='@/assets/teikning2@2x.png')
					h3 Lesfimi
					p.small Lesfimi birtist í fyrirhafnalausum lestri og byggist á færni í tungumálinu. Góð lesfimi getur stutt við góðan lesskilning.
					p.small.see-more(@click='seeMore(2)') Sjá nánar.
				.item
					.image
						img(src='@/assets/teikning4@2x.png')
					h3 Korter á dag
					p.small 15 mínútur á dag geta gert gæfumuninn til að viðhalda lestrarfærni. Við eigum öll korter til að hlusta á börnin okkar lesa.
					p.small.see-more(@click='seeMore(3)') Sjá nánar.
			//-.content-3
				p <a class='see-more' href='http://www.heimiliogskoli.is/laesissattmali/ ' target='_blank'>Áttu korter á dag?</a>
		img.wave(src='@/assets/top_wave.svg')
</template>

<script>
export default {
	methods: {
		seeMore: function (which) {
			this.$emit('seeMore', which);
		}
	}
};
</script>

<style lang='less'>
.about {
	position: relative;
	margin-bottom: 45vw;
	padding-left: 23px;
	padding-right: 23px;
	background-color: white;
	#about {
		position: absolute;
		left: 0px;
		top: 0px;
		transform: translateY(-170px);
	}
	.wave {
		position: absolute;
		bottom: -0,283833211vw;
		left: 0px;
		width: 100%;
		/*
		width: calc(~'100vw + 20px');
		max-width: calc(~'100vw + 20px');
		*/
	}
	.content {
		.content-1 {
			h2 {
				margin: 0px;
				text-align: center;
			}
			p {
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}
		}
		.content-2 {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 50px;
			.item {
				text-align: center;
				img {
					width: 40vw;
				}
				p {
					margin-left: auto;
					margin-right: auto;
					width: 70vw;
				}
				a {
					text-decoration: none;
					color: #F29313;
				}
			}
		}
	}
	@media screen and (min-width: 700px) {
		padding-left: 80px;
		padding-right: 80px;
		.content {
			.content-2 {
				grid-template-columns: 1fr 1fr;
				.item {
					.image {
						position: relative;
						min-height: 228px;
						img {
							max-height: 200px;
							width: auto;
							position: absolute;
							bottom: 0px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
					p {
						width: 100%;
					}
				}
			}
		}
	}
	@media screen and (min-width: 1366px) {
		margin-bottom: 20vw;
		.wave {
			position: absolute;
			bottom: -0,003833211vw;
			transform: translateY(-200px);
			width: 100%;
			left: 0px;
			z-index: 0;
		}
		.content {
			position: relative;
			z-index: 1;
			.content-3 {
				padding-top: 40px;
				width: 100%;
				p {
					font-size: 21px;
					width: 100%;
					max-width: 100%;
					text-align: center;
				}
			}
			.content-2 {
				grid-template-columns: 1fr 1fr 1fr 1fr;
				.item {
					.image {
						position: relative;
						min-height: 228px;
						img {
							max-height: 200px;
							width: auto;
							position: absolute;
							bottom: 0px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
		}
	}
}
</style>
