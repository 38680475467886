<template lang='pug'>
	.home#home
		Header
		VideoHeader
		About(@seeMore='seeMore')
		Videos
		Interviews
		Collaborators
		Footer
		Modal(:activated='modalActive' :which='which' @hide='closeModal')
</template>

<script>
import Header from '@/components/Header.vue';
import VideoHeader from '@/components/VideoHeader.vue';
import About from '@/components/About.vue';
import Videos from '@/components/Videos.vue';
import Interviews from '@/components/Interviews.vue';
import Collaborators from '@/components/Collaborators.vue';
import Footer from '@/components/Footer.vue';
import Modal from '@/components/Modal.vue';
export default {
	name: 'Home',
	components: { Header, VideoHeader, About, Videos, Interviews, Collaborators, Footer, Modal },
	data: function () {
		return {
			modalActive: false,
			which: 0
		};
	},
	created: function () {
	},
	methods: {
		closeModal: function () {
			this.modalActive = false;
		},
		seeMore: function (which) {
			this.which = which;
			this.modalActive = true;
		}
	}
};
</script>

<style lang="less">
.home {
	position: absolute;
	top: 0px;
	left: 0px;
}
</style>
