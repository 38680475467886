import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueCarousel from 'vue-carousel';
import vueSmoothScroll from 'vue-smoothscroll';

Vue.use(VueCarousel);
Vue.use(vueSmoothScroll);
Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app');
