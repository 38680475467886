<template lang='pug'>
.nav(:class='{ extended: nav, show: showNav }')
	.content
	.extend-content(:class='{ fade: navIn }')
		.item.one
			.click(@click='changeNav("about")') Um verkefnið
		.item.two
			.click(@click='changeNav("videos")') Stutt myndbönd
		.item.three
			.click(@click='changeNav("interviews")') Viðtöl
		.item.four
			a(href='https://www.facebook.com/Menntamalastofnun/' target='_blank')
				img.fb(src='@/assets/facebook_button_footer.svg')
	// img.wave(src='@/assets/header_navbar.svg')
	img.nav-burger(src='@/assets/hamburger.svg' @click='viewNav' v-if='!nav')
	img.nav-burger(src='@/assets/hamburger.svg' @click='closeNav' v-else)
	img.nav-logo(src='@/assets/logo.svg' @click='changeNav("home")')
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'Nav',
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			showNav: false,
			nav: false,
			navIn: false,
			blockClose: false
		};
	},
	created: function () {
		window.addEventListener('scroll', (e) => {
			if (window.pageYOffset > 50) {
				this.showNav = true;
			} else {
				this.showNav = false;
			}
		});
	},
	methods: {
		changeNav: function (target) {
			this.$SmoothScroll(document.getElementById(target), 700, null, null, 'both');
		},
		viewNav: function () {
			this.nav = true;
			this.blockClose = true;
			setTimeout(() => {
				this.navIn = true;
				this.blockClose = false;
			}, 300);
		},
		closeNav: function () {
			if (!this.blockClose) {
				this.navIn = false;
				setTimeout(() => {
					this.nav = false;
				}, 300);
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav {
	background-color: white;
	transition: .3s;
	transform: translate(0px, -300px);
	&.show {
		transform: translate(0px, 0px);
	}
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 90px;
	@media screen and (max-width: 1336px) {
		height: 60px;
	}
	border-bottom: 1px solid rgb(184, 182, 182);
	z-index: 100;
	.wave {
		left: -5px;
	}
	.extend-content {
		pointer-events: none;
		margin-top: 20px;
		display: grid;
		grid-template-columns: auto;
		grid-gap: 20px;
		margin-left: 30px;
		.item {
			max-width: 200px;
			cursor: pointer;
			opacity: 0;
			transform: translateY(50px);
			z-index: 99;
			font-size: 14px;
			transition: .2s;
			font-size: 18px;
			margin-left: auto;
			margin-right: 30px;
			font-weight: bold;
			&.one {
				padding-top: 20px;
				transition-delay: .2s;
			}
			&.two {
				padding-top: 30px;
				transition-delay: .1s;
			}
			&.three {
				padding-top: 30px;
				transition-delay: .0s;
			}
			&.four {
				display: none;
				pointer-events: none;
			}
			&:hover {
				color: #FBB03B;
			}
		}
		&.fade {
			pointer-events: initial;
			.item {
				opacity: 1;
				&.one {
					transition-delay: .0s;
				}
				&.two {
					transition-delay: .1s;
				}
				&.three {
					transition-delay: .2s;
				}
			}
		}
	}
	.content {
		height: 51px;
		width: 100%;
		position: absolute;
		background-color: white;
		z-index: 99;
		transition: .3s;
	}
	img {
		position: absolute;
		left: 0px;
		top: 50px;
		height: auto;
		z-index: 98;
		transition: .3s;
	}
	.nav-burger {
		cursor: pointer;
		position: absolute;
		width: 28px;
		left: calc(~'100vw - 59px');
		top: 25px;
		z-index: 99;
		transition: .3s;
	}
	.nav-logo {
		cursor: pointer;
		position: absolute;
		width: 157px;
		left: 23px;
		top: 14px;
		z-index: 99;
	}
	&.extended {
		.content {
			@media screen and (max-width: 1336px) {
				height: 280px;
				border-bottom: 1px solid rgb(184, 182, 182);
			}
		}
		.wave {
			top: 260px;
		}
	}
	@media screen and (min-width: 768px) {
		.wave {
			transform: translateY(-20px);
		}
	}
	@media screen and (min-width: 1366px) {
		.nav-burger {
			display: none;
		}
		.nav-logo {
			position: absolute;
			width: 204px;
			left: 23px;
			top: 22px;
			z-index: 99;
		}
		.wave {
			top: 0px;
		}
		.extend-content {
			pointer-events: initial;
			display: grid;
			grid-template-columns: auto auto auto auto;
			grid-column-gap: 40px;
			float: right;
			padding-right: 0px;
			.item {
				cursor: pointer;
				opacity: 1;
				transform: translateY(0px);
				z-index: 99;
				font-size: 18px;
				transition: .2s;
				color: #232323;
				font-weight: bold;
				text-align: right;
				&.one {
					padding-top: 18px;
					transition-delay: 0s;
				}
				&.two {
					padding-top: 18px;
					transition-delay: 0s;
				}
				&.three {
					padding-top: 18px;
					transition-delay: 0s;
				}
				&.four {
					display: initial;
					pointer-events: none;
					a {
						.fb {
							pointer-events: initial;
							transform: translateY(-40px);
							position: relative;
							right: 0px;
							width: 32px;
						}
					}
				}
				&:hover {
					color: #FBB03B;
				}
			}
		}
	}
}
</style>
