<template lang='pug'>
	.video-header
		#video-header
		img.wave(src='@/assets/bottom_wave.svg')
		.backdrop
			video(controls poster='/mms_main_cover.png')
				source(src='/mms_main.mp4' type='video/mp4')
			.share
				img(src='@/assets/small_facebook_share.svg')
				.share-text(@click='share') Deila á facebook
</template>

<script>
export default {
	methods: {
		share: function () {
			window.FB.ui({
				method: 'share_open_graph',
				action_type: 'og.shares',
				action_properties: JSON.stringify({
					object: {
						'og:url': 'https://www.lesummeira.is/',
						'og:title': 'Lesum meira - Menntamálastofnun',
						'og:description': 'Læsi barna er samvinnuverkefni. Mikilvægt er að öll börn fái tækifæri til að eiga daglega lestrarstund til loka grunnskóla. Foreldrar og aðstandendur verða að vera virkir í læsisuppeldi barna sinna og fylgjast með framvindu og árangri í lestrarfærni.',
						'og:image': 'https://www.lesummeira.is/cover.jpg'
					}
				})
			});
		}
	}
};
</script>

<style lang='less'>
.video-header {
	position: relative;
	margin-top: 155px;
	#video-header {
		position: absolute;
		transform: translateY(-240px);
	}
	.wave {
		position: absolute;
		left: 0px;
		z-index: 0;
		width: 100%;
	}
	.backdrop {
		margin-top: 100px;
		padding-left: 23px;
		padding-right: 23px;
		background-color: white;
		video {
			transform: translate(0px, -100px);
			position: relative;
			z-index: 10;
			width: 100%;
			box-shadow: 0px -8px 60px -6px rgba(0,0,0,0.44);
			margin-bottom: 20px;
		}
		.share {
			position: relative;
			left: 50%;
			display: inline-block;
			transform: translate(-50%, -100px);
			img {
				display: inline-block;
				width: 18px;
				margin-right: 6px;
			}
			.share-text {
				cursor: pointer;
				display: inline-block;
				font-size: 13px;
				color: #1A3553;
				font-weight: bold;
				transform: translateY(-5px);
			}
		}
	}
	@media screen and (min-width: 600px) {
		margin-top: 220px;
	}
	@media screen and (min-width: 768px) {
		.wave {
			transform: translateY(-70px);
		}
		.backdrop {
			padding-left: 80px;
			padding-right: 80px;
			video {
				position: relative;
				z-index: 40;
				left: 50%;
				transform: translate(-50%, -100px);
				max-width: 778px;
			}
			.share {
				width: 100%;
				max-width: 778px;
				left: 50%;
				text-align: right;
				transform: translate(-50%, -80px);
			}
		}
	}
	@media screen and (min-width: 1036px) {
		.wave {
			transform: translateY(-140px);
		}
	}
	@media screen and (min-width: 1460px) {
		.wave {
			transform: translateY(-180px);
		}
	}
	@media screen and (min-width: 1715px) {
		.wave {
			transform: translateY(-250px);
		}
		.backdrop {
			.share {
				display: block;
			}
		}
	}
	@media screen and (min-width: 2100px) {
		.wave {
			transform: translateY(-300px);
		}
	}
	@media screen and (min-width: 2567px) {
		.wave {
			transform: translateY(-350px);
		}
	}
}
</style>
