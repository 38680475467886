<template lang='pug'>
	.header
		.content-1
			img.big-illustration(src='@/assets/teikning_stor@2x.png')
		.content-2
			img.logo(src='@/assets/logo.svg')
			h1 Læsi í krafti foreldra
			p Læsi barna er samvinnuverkefni. Mikilvægt er að öll börn fái tækifæri til að eiga daglega lestrarstund til loka grunnskóla. Foreldrar og aðstandendur verða að vera virkir í læsisuppeldi barna sinna og fylgjast með framvindu og árangri í lestrarfærni.
			.button(@click='changeNav("video-header")') Sjá meira
</template>

<script>
export default {
	methods: {
		seeMore: function () {
			this.$emit('seeMore', 1);
		},
		changeNav: function (target) {
			this.$SmoothScroll(document.getElementById(target), 700, null, null, 'both');
		}
	}
};
</script>

<style lang='less'>
.header {
	min-height: 100vh;
	transform: translateY(-20px);
	margin-left: 23px;
	.content-1 {
		.big-illustration {
			width: 400px;
		}
	}
	.content-2 {
		.button {
			margin-top: 35px;
			cursor: pointer;
			width: 148px;
			height: 42px;
			border-radius: 22px;
			background-color: #FBB03B;
			color: black;
			font-size: 18px;
			text-align: center;
			line-height: 45px;
			font-weight: bold;
			border: 2px solid #FBB03B;
			transition: .3s;
			&:hover {
				background-color: rgba(251, 177, 59, 0.514);
			}
		}
		margin-right: 23px;
		.logo {
			width: 205px;
		}
	}
	@media screen and (min-width: 440px) {
		margin-top: 35px;
		margin-right: 23px;
		transform: translateY(0px);
		text-align: center;
		.content-1 {
			margin-left: auto;
			margin-right: auto;
		}
		.content-2 {
			text-align: left;
		}
	}
	@media screen and (min-width: 768px) {
		margin-left: 40px;
		display: grid;
		grid-template-areas:
		'area2 area2 area1';
		grid-column-gap: 10px;
		.content-1 {
			grid-area: area1;
			.big-illustration {
				width: 300px;
				transform: translateY(40px);
			}
		}
		.content-2 {
			grid-area: area2;
			margin-top: 20px;
			transform: translateY(0px);
			img {
				margin-bottom: 0px;
			}
			h1 {
				margin-bottom: 30px;
			}
		}
	}
	@media screen and (min-width: 968px) {
		.content-1 {
			.big-illustration {
				width: 500px;
				transform: translateY(80px);
			}
		}
		.content-2 {
			transform: translateY(40px);
		}
	}
	@media screen and (min-width: 1140px) {
		.content-1 {
			.big-illustration {
				width: 600px;
				transform: translateY(40px);
			}
		}
		.content-2 {
			transform: translateY(90px);
		}
	}
	@media screen and (min-width: 1366px) {
		.content-1 {
			.big-illustration {
				width: 700px;
				transform: translateY(40px);
			}
		}
		.content-2 {
			transform: translateY(120px);
			img {
				margin-bottom: 50px;
			}
		}
	}
	@media screen and (min-width: 1600px) {
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
